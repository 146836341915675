<template>
  <div :style="background" class="login">
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100">
        <CCol col="12">
          <CCard class="p-0 m-auto">
            <CCardBody class="p-4">              
              <CRow>
                <CCol md="12" lg="12" class="pt-0 pb-0">
                  <div v-if="!showPasswordResetForm">
                    <h1 class="mb-3">{{ $t('login.welcome_back') }}</h1>
                    <CForm @submit.prevent="login">
                      <span><strong>{{ $t('login.Email') }}</strong></span>
                      <CInput class="mt-2 mb-2" :placeholder="emailFieldPlaceholder" autocomplete="username email" v-model="email" id="email" tabindex="1"></CInput>
                      <span><strong>{{ $t('login.Password') }}</strong></span>
                      <CInput class="mt-2 mb-0" :placeholder="$t('login.Your_password')" type="password" autocomplete="curent-password" v-model="password" tabindex="2"></CInput>
                      <CRow>
                        <CCol md="6" lg="6" class="pb-0">
                          <CButton @click="openForgotPassword()" color="secondary" class="w-100">{{ $t('login.forgot_password') }}</CButton>
                        </CCol>
                        <CCol md="6" lg="6" class="pb-0">
                          <CButton type="submit" color="primary" class="w-100" tabindex="3" :disabled="loginButtonDisabled">{{$t('login.login_button')}}</CButton>
                        </CCol>
                        <CCol v-if="errorMessage" col="12" class="pb-0 text-center">
                          <p class="m-0">{{ errorMessage }}</p>                        
                        </CCol>
                      </CRow>
                    </CForm>
                  </div>
                  <div v-else>
                    <h1 v-bind:class="{'mb-2_5' : !forgotPasswordMailSend}">{{ $t('login.Forgot_your_password') }}</h1>

                    <div v-if="!forgotPasswordMailSend">
                      <CForm @submit.prevent="login">
                        <span><strong>{{ $t('login.What_is_your_email_address') }}</strong></span>
                        <CInput class="mt-2 mb-0" :placeholder="$t('login.Email_address')" autocomplete="username email" v-model="forgotPasswordData.email"></CInput>                                          
                        <CRow>
                          <CCol md="6" lg="6" class="pb-0">
                            <CButton @click="showPasswordResetForm = false;" color="secondary" class="w-100">
                              <i class="fas fa-times mr-2"/>{{ $t('Cancel') }}
                            </CButton>
                          </CCol>
                          <CCol md="6" lg="6">
                            <CButton @click="checkEmail()" color="primary" class="w-100" :disabled="!forgotPasswordData.email">
                              <i class="fas fa-paper-plane mr-2"/>{{ $t('login.Send_password_reset_link') }}
                            </CButton>
                          </CCol>
                          <CCol v-if="forgotPasswordErrorMessage" col="12" class="pt-0 pb-0 text-center">
                            <p class="m-0">{{ forgotPasswordErrorMessage }}</p>                        
                          </CCol>
                        </CRow>
                      </CForm>
                    </div>
                    <div v-else>
                      <CRow>
                        <CCol md="12" lg="12">
                          <p class="mt-0 mb-3">{{ $t('login.Email_received') }}</p>
                          <CButton @click="showPasswordResetForm = false;" color="secondary" class="w-100">
                            <i class="fas fa-times mr-2"/>{{ $t('close') }}
                          </CButton>                            
                        </CCol>
                      </CRow>                        
                    </div>
                      
                  </div>
                </CCol>
              </CRow>              
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>

    <div class="logo_placeholder" :class="{'without_filter' : environmentVariables.environment_tag === 'vitaalbegrip'}">
      <div v-if="environmentVariables.company_id_external && environmentVariables.company_image_id">
        <img v-if="cdnBaseUrl" :src="cdnBaseUrl + '/base/dashboard/' + environmentVariables.company_id_external + '/' + environmentVariables.company_image_id + '.png'"/>
      </div>
      <div v-else-if="environmentVariables.environment_id_external">
        <img v-if="environmentVariables.environment_tag === 'harryhr'" src="img/brand/harry_hr_logo_23_06_2022.png"/>
        <img v-if="environmentVariables.environment_tag === 'employalty'" src="img/brand/employalty_logo.png"/>
        <img v-if="environmentVariables.environment_tag === 'vitaalbegrip'" src="img/brand/vitaal_begrip_logo.png"/>
      </div>      
    </div>      
  </div>  
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',
  data(){
    return {
      cdnBaseUrl: null,
      email : "",
      password : "",      
      copyrightText: null,
      errorMessage: null,     
      background : {
        backgroundImage: null,
        backgroundRepeat: 'repeat',
      },
      loginButtonDisabled: false,
      showPasswordResetForm: false,
      forgotPasswordMailSend: false,
      forgotPasswordErrorMessage: null,
      forgotPasswordData: { platform: 'dashboard', email: null },
      emailFieldPlaceholder: null,
      platformHostname: null,
      environmentVariables: {
        company_id_external: null,
        environment_id_external: null
      },
      companyVariables: {
        company_id_external: null
      }
    }
  },
  methods: {
    login() {
      // Disable the login button
      this.loginButtonDisabled = true;
      // Set the email and password variables
      let email = this.email;
      let password = this.password;
      // Login
      this.$store.dispatch('login', { email, password })
      .then(res => {
        // Reset the errorMessage value
        this.errorMessage = false;
        // Get the company platform variables
        this.getCompanyVariables();
        // Set the userAuthenticationSet and authenticateUser variables
        let userAuthenticationSet = res.data.data.user_authentication_set;        
        let authenticateUser = res.data.data.authenticate_user;
        // Navigate to authentication setup page
        if(userAuthenticationSet === false) this.$router.push('/auth/setup');
        // Navigate to authentication code page
        else if(userAuthenticationSet === true && authenticateUser === true) this.$router.push('/auth/verify');
        // Navigate to dashboard homepage
        else this.$router.push('/');
        // Enable the login button
        this.loginButtonDisabled = false;        
      })
      .catch(err => {
        // Set error message when user is not authorized to login
        if(err.response.data.code === 'Not authorized') this.errorMessage = this.$t('login.error_message');
        // Set error message when license is deactivated
        else if(err.response.data.code === 'License deactivated') this.errorMessage = this.$t('login.license_deactivated');        
        // Enable the login button
        this.loginButtonDisabled = false;
      })
    },
    getEnvironmentVariables() {
      // Get the hostname
      this.platformHostname = window.location.hostname;
      // Set the params
      let params = {};
      params.hostname = this.platformHostname;

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/platform/environments/dashboard', params)
      .then(res => {
        this.environmentVariables = res.data.data;
        // Set the dashboard title
        document.title = this.environmentVariables.dashboard_title;
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardDefaultPrimary', this.environmentVariables.primary_color);        
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardPrimary', this.environmentVariables.primary_color);
        // Set the dashboard secondary color
        document.documentElement.style.setProperty('--DashboardSecondary', this.environmentVariables.secondary_color);
        // Set the dashboard font
        document.documentElement.style.setProperty('--DashboardFontFamily', this.environmentVariables.font_family);
        // Set the background image
        this.background.backgroundImage =  "linear-gradient(45deg, rgba(" + this.environmentVariables.primary_color_rgb + ", 0.85), rgba(" + this.environmentVariables.primary_color_rgb + ", 0.9)), url('./img/login/harry_hr_pattern.png')";
        // Set the copyright text
        this.copyrightText = this.environmentVariables.copyright;
        
        // Set the favicon href and the placeholder for the email field
        if(this.environmentVariables.environment_tag ==='harryhr') {
          document.querySelector("link[rel~='icon']").href = 'img/brand/harry_hr_favicon_23_06_2022.png';
          this.emailFieldPlaceholder = "📩 Hi@Harryhr.com";
        } else if(this.environmentVariables.environment_tag ==='employalty') {
          document.querySelector("link[rel~='icon']").href = 'img/brand/employalty_favicon.png';
          this.emailFieldPlaceholder = "📩 Hi@Employalty.com";
        }        
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    getCompanyVariables() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/variables/dashboard/company')
      .then(res => {
        this.companyVariables = res.data.data;
        // Set the dashboard title
        document.title = this.companyVariables.dashboard_title;
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardDefaultPrimary', this.companyVariables.environment_primary_color);        
        // Set default color variables
        let primaryColor = null;
        let secondaryColor = null;
        // Set the primaryColor value
        (this.companyVariables.primary_color) ? primaryColor = this.companyVariables.primary_color : primaryColor = this.companyVariables.environment_primary_color;
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardPrimary', primaryColor);
        // Set the primaryColor value
        (this.companyVariables.secondary_color) ? secondaryColor = this.companyVariables.secondary_color : secondaryColor = this.companyVariables.environment_secondary_color;
        // Set the dashboard secondary color
        document.documentElement.style.setProperty('--DashboardSecondary', secondaryColor);
        // Set the dashboard font
        document.documentElement.style.setProperty('--DashboardFontFamily', this.companyVariables.font_family);        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    openForgotPassword() {
      // Reset the forgot password variables
      this.forgotPasswordData = { platform: 'dashboard', email: null };
      this.forgotPasswordErrorMessage = null;
      this.forgotPasswordMailSend = false;
      // Update showPasswordResetForm value
      this.showPasswordResetForm = true;      
    },
    checkEmail() {
      let params = {};
      // Set the params        
      params.forgotPasswordData = this.forgotPasswordData;
      // Reset the forgotPasswordErrorMessage value
      this.forgotPasswordErrorMessage = null;
      // Check the provided email address
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/resetpassword', params)
      .then(res => {
        if(res.data.code === 'Reset link sent') this.forgotPasswordMailSend = true;
      })
      .catch(err => {
        if(err.response.data.code === 'Email not found') {
          this.forgotPasswordErrorMessage = this.$t('login.Email_address_not_found');
        } else if(err.response.data.code === 'License deactivated') {
          this.forgotPasswordErrorMessage = this.$t('login.Licence_deactivated');
        }
      });
    }
  },
  mounted: function() {
    // Set the base URL for the CDN
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    // Get the default platform variables
    this.getEnvironmentVariables();
    // Focus on the email field of the login form
    document.getElementById("email").focus();
  }
}
</script>
